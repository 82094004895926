import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="address"
export default class extends Controller {
  static targets =
    ["searchPanel", "zipCode", "baseAddress", "detailAddress", "extraAddress"]
  static classes = ["show", "hide"]

  themeObj = {
    bgColor: '#F5F5F4', //바탕 배경색
    // searchBgColor: "#0B65C8", //검색창 배경색
    searchBgColor: '#F5F5F4', //검색창 배경색
    contentBgColor: '#F5F5F4', //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
    //pageBgColor: '', //페이지 배경색
    //textColor: '', //기본 글자색
    queryTextColor: '#000000', //검색창 글자색
    //postcodeTextColor: "", //우편번호 글자색
    //emphTextColor: "", //강조 글자색
    outlineColor: '#000000', //테두리
  }

  loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script ${url}`));
      document.head.appendChild(script);
    });
  }

  async connect() {
    await this
      .loadScript("//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js")
  }

  search() {
    // 현재 scroll 위치를 저장해놓는다.
    const savedScrollPoint =
      Math.max(document.body.scrollTop, document.documentElement.scrollTop)

    new daum.Postcode({
      oncomplete: (data) => {
        // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

        // 각 주소의 노출 규칙에 따라 주소를 조합한다.
        // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.

        // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
        this.baseAddressTarget.value = this.getAddr(data)

        // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
        this.extraAddressTarget.value = this.getExtraAddr(data)

        // 우편번호와 주소 정보를 해당 필드에 넣는다.
        this.zipCodeTarget.value = data.zonecode;

        // 커서를 상세주소 필드로 이동한다.
        this.detailAddressTarget.focus();

        // iframe을 넣은 element를 안보이게 한다.
        // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
        this.hide()

        // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
        document.body.scrollTop = savedScrollPoint;
      },
      // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분.
      // iframe을 넣은 element의 높이값을 조정한다.
      onresize: (size) => {
        this.searchPanelTarget.style.height = size.height + 'px';
      },
      theme: this.themeObj,
      width : '100%',
      height : '100%'
    }).embed(this.searchPanelTarget);

    this.show()
  }

  // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
  // R은 사용자가 도로명 주소를 선택했을 경우
  getAddr = (data) =>
    (data.userSelectedType === 'R') ? data.roadAddress : data.jibunAddress

  getExtraAddr = (data) => {
    if(data.userSelectedType !== 'R') {
      return ''
    }

    let extraAddr = ''

    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
    extraAddr +=
      (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) ?
        data.bname : ''

    // 건물명이 있고, 공동주택일 경우 추가한다.
    const buildingName =
      (data.buildingName !== '' && data.apartment === 'Y') ?
        data.buildingName : ''

    if (buildingName !== '') {
      extraAddr += (extraAddr === '') ? '' : ', '
      extraAddr += buildingName
    }

    // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
    extraAddr = (extraAddr === '') ? '' : ' (' + extraAddr + ')'

    return extraAddr
  }

  show() {
    this.searchPanelTarget.classList.add(...this.showClasses)
    this.searchPanelTarget.classList.remove(...this.hideClasses)
  }

  hide() {
    this.searchPanelTarget.classList.add(...this.hideClasses)
    this.searchPanelTarget.classList.remove(...this.showClasses)
  }
}
