import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-btn"
export default class extends Controller {
  static targets = ['labels']
  static values = { checked: String }
  static classes = ['checked']

  connect() {
    this.labelsTargets.forEach((label) => {
      if (this.getRadioInput(label).value === this.checkedValue) {
        this.click({ target: label })
      }
    })
  }

  click(event) {
    this.resetLabels()

    const label = event.target.closest('label')
    const radioInput = this.getRadioInput(label)

    label.classList.add(...this.checkedClasses)
    radioInput.checked = true
  }

  getRadioInput(label) {
    return label.querySelector('input[type="radio"]');
  }

  resetLabels() {
    this.labelsTargets.forEach((label) => {
      label.classList.remove(...this.checkedClasses)
      this.getRadioInput(label).checked = false
    })
  }
}
