import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  static targets = ["progressBar"]
  static values = {
    dismissAfter: { type: Number, default: 5000 }
  }
  static classes = [
    "show",
    "hide"
  ]

  initialize() {
    this.hide()
    this.element.classList.remove('hidden');
  }

  connect() {
    if (this.isPreview) { return }

    // Auto show (default hidden)
    // NOTE: https://stackoverflow.com/questions/65632623/js-css-transition-not-occurring-unless-within-a-settimeout
    this.element.offsetHeight
    this.show()

    if (this.dismissAfterValue > 0) {
      setTimeout(() => {
        this.close()
      }, this.dismissAfterValue)
    }

    if (this.hasProgressBarTarget) {
      this.progressBarTarget.classList.replace('w-0', 'w-full')
    }
  }

  close() {
    this.hide()

    this.element.addEventListener('transitionend', () => {
      this.element.remove()
    }, { once: true })
  }

  show() {
    this.element.classList.remove(...this.hideClasses)
    this.element.classList.add(...this.showClasses)
  }

  hide() {
    this.element.classList.remove(...this.showClasses)
    this.element.classList.add(...this.hideClasses)
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview')
  }
}
