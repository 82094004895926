import { Controller } from "@hotwired/stimulus"
import * as PortOne from "@portone/browser-sdk/v2";

// Connects to data-controller="pay"
export default class extends Controller {
  static targets = ["form"]
  static values = {
    storeId: String,
    channelKey: String,
    orderName: String,
    totalAmount: Number,
    currency: String,
    method: String,
    completeUrl: String,
  }

  async submitOrder(event) {
    event.preventDefault()

    const formData = new FormData(this.formTarget)
    const actionUrl = this.formTarget.action

    try {
      const response = await fetch(actionUrl, {
        method: 'POST',
        body: formData,
        headers: { 'Accept': 'application/json' },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json()

      if (response.status === 201 || response.status === 200) {
        this.request(event, responseData.payment.serial_number)
      } else {
        console.error('Form submission failed:', responseData.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  async request(event, serialNumber) {
    event.preventDefault()

    console.log('Requesting payment for serial number:', serialNumber)

    if (serialNumber === '') { return console.log('Serial number is empty') }

    const response = await PortOne.requestPayment({
      storeId: this.storeIdValue,
      channelKey: this.channelKeyValue,
      paymentId: serialNumber,
      orderName: this.orderNameValue,
      totalAmount: this.totalAmountValue,
      currency: this.currencyValue,
      payMethod: this.methodValue.toUpperCase(),
      windowType: {
        mobile: 'REDIRECTION'
      },
      redirectUrl: this.getCompleteUrl(serialNumber),
    })

    if (response.code != null) {
      // TODO: alert message to user
      return console.log('Payment request has failed', response.code, response.message)
    }

    window.location.href = this.getCompleteUrl(serialNumber)
  }

  getCompleteUrl(serialNumber) {
    const url = new URL(this.completeUrlValue)
    url.searchParams.append('paymentId', serialNumber)
    return url.toString()
  }
}
